<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="roomName"
                    label="直播间名称"
                >
                    <el-input
                        v-model="queryFormModel.roomName"
                        placeholder="请输入直播间名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="liveType"
                    label="直播间类型"
                >
                    <dictionaries-picker
                        type="liveType"
                        v-model="queryFormModel.liveType"
                    />
                </el-form-item>
                <el-form-item
                    prop="anchorName"
                    label="主播昵称"
                >
                    <el-input
                        v-model="queryFormModel.anchorName"
                        placeholder="请输入主播昵称"
                    />
                </el-form-item>
                <el-form-item
                    prop="anchorWechat"
                    label="主播微信号"
                >
                    <el-input
                        v-model="queryFormModel.anchorWechat"
                        placeholder="请输入主播微信号"
                    />
                </el-form-item>
                <el-form-item
                    prop="liveStatus"
                    label="直播间状态"
                >
                    <dictionaries-picker
                        type="liveStatus"
                        v-model="queryFormModel.liveStatus"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="roomName"
                    label="直播间名称"
                    min-width="100"
                />
                <el-table-column
                    prop="liveTypeName"
                    label="直播类型"
                    min-width="100"
                />
                <el-table-column
                    label="直播时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.startTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        <br>
                        {{ scope.row.endTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="anchorName"
                    label="主播昵称"
                    min-width="100"
                />
                <el-table-column
                    prop="anchorWechat"
                    label="主播微信号"
                    min-width="100"
                />
                <el-table-column
                    label="背景墙图片"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.coverImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    label="分享封面图片"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.shareImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="screenOrientationName"
                    label="屏幕方向"
                    min-width="100"
                />
                <el-table-column
                    prop="liveFunctionListStr"
                    label="直播间功能"
                    min-width="100"
                />
                <el-table-column
                    prop="liveStatusName"
                    label="直播间状态"
                    min-width="100"
                />
                <el-table-column
                    prop="remarks"
                    label="备注"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/pm/live/liveRoom/goods/' + scope.row.id)"
                        >
                            管理商品
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="1200px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="liveType"
                    label="直播类型"
                    label-width="7em"
                >
                    <dictionaries-picker
                        type="liveType"
                        v-model="addDialog.formModel.liveType"
                    />
                </el-form-item>
                <el-form-item
                    prop="screenOrientation"
                    label="屏幕方向"
                    label-width="7em"
                >
                    <dictionaries-picker
                        type="screenOrientation"
                        v-model="addDialog.formModel.screenOrientation"
                    />
                </el-form-item>
                <el-form-item
                    prop="roomName"
                    label="直播间名称"
                    label-width="7em"
                >
                    <el-input
                        v-model="addDialog.formModel.roomName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="liveTime"
                    label="直播时间"
                    label-width="7em"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        size="small"
                        v-model="addDialog.formModel.liveTime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="anchorName"
                    label="主播昵称"
                    label-width="7em"
                >
                    <el-input
                        v-model="addDialog.formModel.anchorName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="anchorWechat"
                    label="主播微信号"
                    label-width="7em"
                >
                    <el-input
                        v-model="addDialog.formModel.anchorWechat"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="anchorBuyerId"
                    label="关联会员"
                    label-width="7em"
                >
                    <div
                        class="ma-b"
                    >
                        <el-button-group>
                            <el-button
                                type="success"
                                icon="el-icon-plus"
                                size="small"
                                @click="onAddSelect"
                            >
                                选择会员
                            </el-button>
                        </el-button-group>
                    </div>

                    <!-- 表格 -->
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="selectDataList"
                        style="width: 100%"
                    >
                        <el-table-column
                            prop="distributorName"
                            label="姓名"
                            min-width="100"
                        />
                        <el-table-column
                            prop="mobile"
                            label="电话"
                            min-width="100"
                        >
                            <template slot-scope="scope">
                                {{ scope.row.distributorCountryCode }}-{{ scope.row.distributorTel }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="wechatId"
                            label="微信号"
                            min-width="100"
                        />
                        <el-table-column
                            prop="distributorLevelName"
                            label="分销商等级"
                            min-width="100"
                        />
                        <el-table-column
                            label="推荐人"
                            min-width="100"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.referenceDistributorName }}</div>
                                <div>{{ scope.row.referenceDistributorCountryCode }}-{{ scope.row.referenceDistributorTel }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="加入时间"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                {{ scope.row.joinTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item
                    prop="coverImageUrl"
                    label="背景墙"
                    label-width="7em"
                >
                    <upload
                        action="/rs/attachment/uploadLiveImage"
                        :limit="1"
                        v-model="addDialog.formModel.coverImageUrl"
                        tips="建议尺寸：800像素 * 640像素,图片大小不得超过1M"
                    />
                </el-form-item>
                <el-form-item
                    prop="shareImageUrl"
                    label="分享封面"
                    label-width="7em"
                >
                    <upload
                        action="/rs/attachment/uploadLiveImage"
                        :limit="1"
                        v-model="addDialog.formModel.shareImageUrl"
                        tips="建议尺寸：1080像素 * 1920像素,图片大小不得超过2M"
                    />
                </el-form-item>
                <el-form-item
                    prop="liveFunction"
                    label="直播间功能"
                    label-width="7em"
                >
                    <checkbox-group-andOr
                        type="liveFunction"
                        v-model="addDialog.formModel.liveFunction"
                    />
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="备注"
                    label-width="7em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.remarks"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>


        <!-- 弹框 -->
        <el-dialog
            title="选择会员"
            center
            width="1100px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="distributorName"
                    label="姓名"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.distributorName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="distributorTel"
                    label="电话"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.distributorTel"
                        placeholder="请输入电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="distributorLevelId"
                    label="分销商等级"
                >
                    <el-select
                        v-model="selectDialog.queryFormModel.distributorLevelId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in distributorLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="goodsId"
                    ref="selectDialogTable"
                >
                    <el-table-column
                        prop="distributorName"
                        label="姓名"
                        min-width="100"
                    />
                    <el-table-column
                        prop="mobile"
                        label="电话"
                        min-width="100"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.distributorCountryCode }}-{{ scope.row.distributorTel }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="wechatId"
                        label="微信号"
                        min-width="100"
                    />
                    <el-table-column
                        prop="distributorLevelName"
                        label="分销商等级"
                        min-width="100"
                    />
                    <el-table-column
                        label="推荐人"
                        min-width="100"
                    >
                        <template slot-scope="scope">
                            <div>{{ scope.row.referenceDistributorName }}</div>
                            <div>{{ scope.row.referenceDistributorCountryCode }}-{{ scope.row.referenceDistributorTel }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="加入时间"
                        min-width="150"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.joinTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="100"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="small"
                                @click="onSelectConfirm(scope.row)"
                            >
                                选择会员
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <!--<el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    确 定
                </el-button>-->
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'Variable',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                roomName: '',
                liveType: '',
                anchorName: '',
                anchorWechat: '',
                liveStatus: '',
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    liveType: '1',
                    screenOrientation: '1',
                    roomName: '',
                    liveTime: [],
                    anchorName: '',
                    anchorWechat: '',
                    coverImageUrl: [],
                    shareImageUrl: [],
                    liveFunction: '7',
                    remarks: '',
                    anchorBuyerId: '',
                },
                // 表单校验规则
                formRules: {
                    liveType: {
                        required: true,
                        message: '请选择直播方式',
                    },
                    screenOrientation: {
                        required: true,
                        message: '请选择屏幕方向',
                    },
                    roomName: [
                        {
                            required: true,
                            message: '请输入直播间名称',
                            trigger: 'blur',
                        },
                        { min: 3, max: 17, message: '长度在 3 到 17 个字符', trigger: 'blur' },
                    ],
                    anchorName: [
                        {
                            required: true,
                            message: '请输入主播昵称',
                            trigger: 'blur',
                        },
                        { min: 4, max: 15, message: '长度在 4 到 15 个字符', trigger: 'blur' },
                    ],
                    anchorWechat: {
                        required: true,
                        message: '请输入主播微信号',
                        trigger: 'blur',
                    },
                    liveFunction: {
                        required: true,
                        message: '请选择直播间功能',
                        trigger: 'blur',
                    },
                    anchorBuyerId: {
                        required: true,
                        message: '请选择会员',
                        trigger: 'blur',
                    },
                    liveTime: {
                        type: 'array',
                        required: true,
                        message: '请选择开播时间',
                        trigger: 'blur',
                    },
                    coverImageUrl: {
                        type: 'array',
                        required: true,
                        message: '请上传背景墙图片',
                        trigger: 'blur',
                    },
                    shareImageUrl: {
                        type: 'array',
                        required: true,
                        message: '请上传分享封面图片',
                        trigger: 'blur',
                    },
                },
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    distributorName: '', // 姓名
                    distributorTel: '', // 电话
                    distributorLevelId: '',
                    referenceId: '',
                    isAddWechat: '',
                    referenceName: '',
                    joinTime: '',
                    isDistributor: '1',
                    isSalesman: '',
                    approvalStatus: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            // 表格数据
            selectDataList: [],
            //分销等级列表
            distributorLevelList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Pm.LiveRoom.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Pm.LiveRoom.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.Common.formModelMerge(formModel, res);
            });
            this.addDialog.isVisible = true;
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Pm.LiveRoom.save({
                    ...this.addDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        /* -- 事件 -- */
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Mb.Distributor.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm(row) {
            this.selectDataList = [row];
            //初始化数据
            this.addDialog.formModel.anchorBuyerId = row.id;
            this.selectDialog.isVisible = false;
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        initDistributorLevelList() {
            this.$api.Mb.DistributorLevel.select().then(json => {
                this.distributorLevelList = json.data.data;
            });
        },
    },
    created() {
        this.initDistributorLevelList();
    },
};
</script>

<style lang="scss">
</style>
