<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsStatus"
                    label="商品状态"
                >
                    <dictionaries-picker
                        type="liveGoodsStatus"
                        v-model="queryFormModel.goodsStatus"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        新增
                    </el-button>
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="small"
                        @click="onDelete"
                        :disabled="!currentTableSelect.length"
                    >
                        删除
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="100"
                />
                <el-table-column
                    label="商品封面"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.coverImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    label="商品价格"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.priceType == 1">{{ scope.row.goodsPrice.price1 }}</span>
                        <span v-if="scope.row.priceType == 2">{{ scope.row.goodsPrice.price1 }}-{{ scope.row.goodsPrice.price2 }}</span>
                        <span v-if="scope.row.priceType == 3">原价:{{ scope.row.goodsPrice.price1 }}<br></span>
                        <span v-if="scope.row.priceType == 3">现价:{{ scope.row.goodsPrice.price2 }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsStatusName"
                    label="商品状态"
                    min-width="100"
                />
                <el-table-column
                    prop="remarks"
                    label="备注"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                            v-if="scope.row.goodsStatus != 30"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                            v-if="scope.row.goodsStatus != 30"
                        >
                            删除
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableResetAudit(scope.row.id)"
                            v-if="scope.row.goodsStatus == 30"
                        >
                            取消审核
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="1200px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="shopGoodsId"
                    label="商品"
                    label-width="6em"
                >
                    <div
                        class="ma-b"
                        v-if="!addDialog.formModel.id
                            || addDialog.formModel.goodsStatus == 20
                            || addDialog.formModel.goodsStatus == 99"
                    >
                        <el-button-group>
                            <el-button
                                type="success"
                                icon="el-icon-plus"
                                size="small"
                                @click="onAddSelect"
                            >
                                选择商品
                            </el-button>
                        </el-button-group>
                    </div>

                    <!-- 表格 -->
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="selectDataList"
                        style="width: 100%"
                    >
                        <!-- 商品规格 -->
                        <el-table-column
                            label="商品图片"
                            width="120"
                        >
                            <template slot-scope="scope">
                                <ImageList
                                    :data="scope.row.mainImageUrl"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="goodsName"
                            label="商品名称"
                            min-width="100"
                        />
                        <el-table-column
                            prop="goodsSn"
                            label="商品货号"
                            min-width="50"
                        />
                        <el-table-column
                            prop="goodsCatName"
                            label="商品类目"
                            min-width="50"
                        />
                        <el-table-column
                            prop="supplierName"
                            label="供应商名称"
                            min-width="50"
                        />
                        <el-table-column
                            prop="price"
                            label="销售价"
                            min-width="50"
                        />
                        <el-table-column
                            prop="marketPrice"
                            label="市场价"
                            min-width="50"
                        />
                        <el-table-column
                            prop="stock"
                            label="库存"
                            min-width="50"
                        />
                    </el-table>
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                    label-width="6em"
                >
                    <el-input
                        v-model="addDialog.formModel.goodsName"
                        auto-complete="off"
                        :disabled="addDialog.formModel.goodsStatus == 30
                            || addDialog.formModel.goodsStatus == 100"
                    />
                </el-form-item>
                <el-form-item
                    prop="coverImageUrl"
                    label="商品图片"
                    label-width="6em"
                >
                    <upload
                        action="/rs/attachment/uploadGoodsImage"
                        :limit="1"
                        v-model="addDialog.formModel.coverImageUrl"
                        tips="建议尺寸：200像素 * 200像素，图片大小不得超过80K"
                        :disabled="addDialog.formModel.goodsStatus == 30
                            || addDialog.formModel.goodsStatus == 100"
                    />
                </el-form-item>
                <el-form-item
                    prop="priceType"
                    label="价格类型"
                    label-width="6em"
                >
                    <dictionaries-picker
                        type="liveGoodsPriceType"
                        v-model="addDialog.formModel.priceType"
                    />
                </el-form-item>
                <el-form-item
                    prop="price1"
                    label="一口价"
                    label-width="6em"
                    v-if="addDialog.formModel.priceType == 1"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="0"
                        v-model.number="addDialog.formModel.goodsPrice.price1"
                    />
                </el-form-item>
                <el-form-item
                    label="价格区间"
                    label-width="6em"
                    v-if="addDialog.formModel.priceType == 2"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="0"
                        v-model.number="addDialog.formModel.goodsPrice.price1"
                    />
                    -
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="0"
                        v-model.number="addDialog.formModel.goodsPrice.price2"
                    />
                </el-form-item>
                <el-form-item
                    prop="price1"
                    label="原价"
                    label-width="6em"
                    v-if="addDialog.formModel.priceType == 3"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="0"
                        v-model.number="addDialog.formModel.goodsPrice.price1"
                    />
                </el-form-item>
                <el-form-item
                    prop="price2"
                    label="现价"
                    label-width="6em"
                    v-if="addDialog.formModel.priceType == 3"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="0"
                        v-model.number="addDialog.formModel.goodsPrice.price2"
                    />
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="备注"
                    label-width="6em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.remarks"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsStatus"
                />
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm()"
                    v-if="addDialog.formModel.goodsStatus == 20
                        || addDialog.formModel.goodsStatus == 99
                        || addDialog.formModel.goodsStatus == 100"
                >
                    保存
                </el-button>
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm(30)"
                    v-if="!addDialog.formModel.goodsStatus
                        || addDialog.formModel.goodsStatus == 20
                        || addDialog.formModel.goodsStatus == 99"
                >
                    保存并提交审核
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!-- 弹框 -->
        <el-dialog
            title="选择商品"
            center
            width="1100px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSn"
                    label="商品编号"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsSn"
                        placeholder="请输入商品编号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="goodsId"
                    ref="selectDialogTable"
                >
                    <el-table-column
                        label="商品图片"
                        width="120"
                    >
                        <template slot-scope="scope">
                            <ImageList
                                :data="scope.row.mainImageUrl"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodsName"
                        label="商品名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        label="商品货号"
                        min-width="50"
                    />
                    <el-table-column
                        prop="goodsCatName"
                        label="商品类目"
                        min-width="50"
                    />
                    <el-table-column
                        prop="supplierName"
                        label="供应商名称"
                        min-width="50"
                    />
                    <el-table-column
                        prop="price"
                        label="销售价"
                        min-width="50"
                    />
                    <el-table-column
                        prop="marketPrice"
                        label="市场价"
                        min-width="50"
                    />
                    <el-table-column
                        prop="stock"
                        label="库存"
                        min-width="50"
                    />
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="100"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="small"
                                @click="onSelectConfirm(scope.row)"
                            >
                                选择商品
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <!--<el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    确 定
                </el-button>-->
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'LiveGoods',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                goodsName: '',
                goodsStatus: '',
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    shopGoodsId: '',
                    goodsName: '',
                    coverImageUrl: [],
                    priceType: '',
                    goodsPrice: {
                        price1: '',
                        price2: '',
                    },
                    remarks: '',
                    goodsStatus: '',
                },
                // 表单校验规则
                formRules: {
                    shopGoodsId: {
                        required: true,
                        message: '请选择商品',
                        trigger: 'blur',
                    },
                    goodsName: [
                        {
                            required: true,
                            message: '请输入商品名称',
                            trigger: 'blur',
                        },
                        { min: 1, max: 14, message: '长度在 1 到 14 个字符', trigger: 'blur' },
                    ],
                    coverImageUrl: {
                        type: 'array',
                        required: true,
                        message: '请输入变量代码',
                        trigger: 'blur',
                    },
                    priceType: {
                        required: true,
                        message: '请选择价格类型',
                    },
                },
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsName: '', // 商品名称
                    goodsSn: '', // 商品编号
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            // 表格数据
            selectDataList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Pm.LiveGoods.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
                this.selectDataList = [];
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Pm.LiveGoods.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                res.liveGoods.goodsPrice = JSON.parse(res.liveGoods.goodsPrice);
                this.$utils.Common.formModelMerge(formModel, res.liveGoods);
                this.selectDataList = res.goodsList;
            });
            this.addDialog.isVisible = true;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Pm.LiveGoods.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onTableResetAudit(ids) {
            this.$confirm('是否确定要撤回审核！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Pm.LiveGoods.resetAudit({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm(goodsStatus) {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Pm.LiveGoods.save({
                    ...this.addDialog.formModel,
                    goodsStatus: goodsStatus,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        /* -- 事件 -- */
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Gd.Goods.selectByPage({
                ...this.selectDialog.queryFormModel,
                ...pagination,
                goodsStatus: 3,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm(row) {
            this.selectDataList = [row];
            //初始化数据
            this.addDialog.formModel.shopGoodsId = row.id;
            this.addDialog.formModel.goodsName = row.goodsName;
            if (row.skuList.length === 1) {
                //单商品
                this.addDialog.formModel.priceType = 1;
                this.addDialog.formModel.goodsPrice.price1 = row.price;
            } else {
                //多商品
                this.addDialog.formModel.priceType = 2;
                this.addDialog.formModel.goodsPrice.price1 = row.price.split('-')[0];
                this.addDialog.formModel.goodsPrice.price2 = row.price.split('-')[1];
            }
            this.addDialog.formModel.coverImageUrl = [
                { name: row.goodsImage.split(',')[0], address: row.mainImageUrl },
            ];
            this.selectDialog.isVisible = false;
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
    },
};
</script>

<style lang="scss">
</style>
